import React from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";

import ModalWrapper from "Components/Modal";
import SanitizedHTML from "Components/SanitizedHTML";
import Heading2 from "Components/styleguide/Heading2";
import Button from "Components/Button";

import * as S from "./TemplateSelectModal.styles";

const TemplateSelectModal = ({ closeModal, isOpen, onSave, template }) => {
  const intl = useIntl();

  const icon = template.info?.image;
  const title = template.info?.name;
  const description = template.info?.description;
  const notes = template.info?.notes || [];

  const empty = template.info?.id === "platformsh/empty";
  const submitId = `select-${template.info?.id?.replace(/\//gm, "-")}`;

  return (
    <ModalWrapper
      isOpen={isOpen}
      closeModal={closeModal}
      onRequestClose={closeModal}
      modalClass="modal-medium modal-add-template"
    >
      <S.HeaderWrapper>
        {empty ? (
          <Heading2>
            {intl.formatMessage({
              id: "template.modal.title.blank",
              defaultMessage: "Create a blank project"
            })}
          </Heading2>
        ) : (
          <>
            <S.BaseIcon className="base-icon">
              <img alt={title} src={icon} />
            </S.BaseIcon>
            <Heading2>{title}</Heading2>
          </>
        )}
      </S.HeaderWrapper>
      <S.TemplateInfo
        className={`description${empty ? " blank-description" : ""}`}
      >
        <S.Subtitle>
          {intl.formatMessage({
            id: "template.modal.overview",
            defaultMessage: "Overview"
          })}
        </S.Subtitle>
        <SanitizedHTML
          className="description"
          allowedTags={["strong", "b", "br", "p", "em", "i"]}
          html={description}
        />
      </S.TemplateInfo>

      {!empty &&
        notes.map(
          (note, i) =>
            (note.heading || note.content) && (
              <S.TemplateInfo key={i} className="apps-services">
                {note.heading && <S.Subtitle>{note.heading}</S.Subtitle>}
                {note.content && (
                  <SanitizedHTML
                    className="description"
                    allowedTags={["strong", "b", "br", "p", "em", "i"]}
                    html={note.content}
                  />
                )}
              </S.TemplateInfo>
            )
        )}
      <S.ActionLinks>
        <Button className="secondary" onClick={closeModal}>
          {intl.formatMessage({
            id: "template.modal.cancel",
            defaultMessage: "cancel"
          })}
        </Button>
        <Button
          id={submitId}
          type="submit"
          aria-label="select template"
          onClick={onSave}
          className="primary"
        >
          {intl.formatMessage({
            id: `template.modal.save.${empty ? "blank" : "template"}`
          })}
        </Button>
      </S.ActionLinks>
    </ModalWrapper>
  );
};

TemplateSelectModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  template: PropTypes.object
};

export default TemplateSelectModal;
