import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import moment from "moment";

import InlineBanner from "Components/InlineBanner";

import Timeframe from "../../components/TimeframeSelector";
import LastUpdated from "../../components/LastUpdated";
import { EmptyMetrics } from "../../components/Errors";
import ActivityLogModal from "../../components/ActivityLogModal/ActivityLogModal";

import { gridTimeframes } from "../../settings";

import Service from "../../containers/Service";
import useGridWorker from "../../hooks/useGridWorker";
import useRange from "../../hooks/useRange";

import * as V from "../../views/views.styles";
import * as S from "./Grid.styles";
import Skeleton from "./Grid.skeleton";

import ToggleActivitiesOverlay from "../../components/ToggleActivitiesOverlay";

const minimumGridRange = 180;

const Grid = ({ chorusURL, collection }) => {
  const [maxZoom, setMaxZoom] = useState(false);
  const [selectedTimeframe, setSelectedTimeframe] = useRange(
    gridTimeframes,
    minimumGridRange
  );

  const [services, [from, to], isLoadingMetrics] = useGridWorker(
    chorusURL,
    collection,
    selectedTimeframe
  );

  const minimumGridInterval =
    selectedTimeframe?.interval < 60 ? 60 : selectedTimeframe.interval;

  const onBrush = useCallback(
    (from, to) => {
      if (maxZoom) {
        setSelectedTimeframe({
          from: selectedTimeframe.from,
          to: selectedTimeframe.to
        });
        return;
      }
      const selectedRange = moment(to).diff(moment(from), "minutes");
      if (selectedRange < 3) {
        const newEndpoint = moment(from).add(3, "minutes").format();
        setMaxZoom(true);
        setSelectedTimeframe({ from, to: newEndpoint });
      } else {
        setSelectedTimeframe({ from, to });
      }
    },
    [maxZoom, setMaxZoom, setSelectedTimeframe]
  );

  const onTimeframeChange = useCallback(
    timeframe => {
      setMaxZoom(false);
      setSelectedTimeframe(timeframe.label);
    },
    [setMaxZoom, setSelectedTimeframe]
  );

  const hosts = useMemo(() => ["average"], []);

  if (services?.error) {
    return (
      <InlineBanner padding="16px" level="error">
        {services.error?.message}
      </InlineBanner>
    );
  }

  const isLoading = typeof services === "undefined" || isLoadingMetrics;
  const showServices = !!services;
  const showEmptyMetrics =
    !isLoading &&
    services?.services &&
    Object.keys(services.services).length === 0;

  return (
    <>
      <ActivityLogModal />
      <V.Header>
        <V.Heading>
          <FormattedMessage id="metrics.grid.heading" />
        </V.Heading>
        <V.TimeframeColumn>
          {selectedTimeframe && (
            <LastUpdated
              interval={minimumGridInterval}
              lastDataPoint={to}
              isCustomTimeframe={!selectedTimeframe.id}
            />
          )}
          <V.Row style={{ marginLeft: "auto" }}>
            <ToggleActivitiesOverlay />
            <Timeframe
              selectedTimeframe={selectedTimeframe}
              timeframes={gridTimeframes}
              onChange={onTimeframeChange}
              to={to}
              from={from}
            />
          </V.Row>
        </V.TimeframeColumn>
      </V.Header>
      <S.Layout>
        {isLoading && !services && <Skeleton />}
        {showEmptyMetrics && <EmptyMetrics />}
        {showServices &&
          services.services.map(service => (
            <Service
              key={service.id}
              service={service}
              chorusURL={chorusURL}
              selectedTimeframe={selectedTimeframe}
              onBrush={onBrush}
              hosts={hosts}
              isLoading={isLoading}
              kind="grid"
              activities={services.activities}
              maxZoom={maxZoom}
            />
          ))}
      </S.Layout>
    </>
  );
};

Grid.propTypes = {
  chorusURL: PropTypes.string,
  collection: PropTypes.string
};

export default React.memo(Grid);
