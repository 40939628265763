import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";

import { capitalize } from "Libs/utils";

import ButtonWrapper from "Components/ButtonWrapper";
import CopyableArea from "Components/CopyableArea";
import Error from "Components/Error";
import Heading4 from "Components/styleguide/Heading4";
import InfoDialog from "Components/InfoDialog";
import Loading from "Components/Loading";

import * as S from "./DomainItem.styles";

const DomainItem = ({
  cname,
  domain,
  errors = {},
  isDefault = false,
  isLoading,
  makeItDefault,
  onDelete
}) => {
  const intl = useIntl();

  const showDeleteButton = useMemo(
    () => domain?.hasPermission && domain?.hasPermission("#delete"),
    [domain]
  );

  const handleMakeItDefault = e => {
    e?.preventDefault();
    makeItDefault();
  };

  return (
    <S.Wrapper>
      <LiveMessage
        message={intl.formatMessage({ id: "edit_domain" })}
        aria-live="polite"
      />

      <S.FormWrapper aria-labelledby="edit-domain-heading">
        {errors.error && <Error>{errors.error}</Error>}

        <S.InputWrapper>
          <S.Input
            id="name"
            label={intl.formatMessage({ id: "settings.domains.name" })}
            value={domain.name}
            isDisabled={true}
          />
        </S.InputWrapper>

        <S.Note>
          {intl.formatMessage(
            { id: "settings.domains.traffic" },
            {
              code: (...chunks) => <code>{chunks}</code>, // eslint-disable-line react/display-name
              dt: (...chunks) => <dt>{chunks}</dt>, // eslint-disable-line react/display-name
              dd: (...chunks) => <dd>{chunks}</dd> // eslint-disable-line react/display-name
            }
          )}
        </S.Note>

        <S.Config>
          <Heading4>
            {intl.formatMessage({ id: "settings.domains.config.title" })}
          </Heading4>
          <p>
            {intl.formatMessage(
              { id: "settings.domains.config.line_1" },
              {
                // eslint-disable-next-line react/display-name
                a: (...chunks) => (
                  <a
                    href={intl.formatMessage({
                      id: "links.documentation.domain"
                    })}
                    target="_blank"
                  >
                    {chunks}
                  </a>
                )
              }
            )}
          </p>
          <p>
            {intl.formatMessage(
              { id: "settings.domains.config.line_2" },
              {
                // eslint-disable-next-line react/display-name
                a: (...chunks) => (
                  <a
                    href={intl.formatMessage({
                      id: "links.documentation.domain"
                    })}
                    target="_blank"
                  >
                    {chunks}
                  </a>
                )
              }
            )}
          </p>
        </S.Config>

        <S.CopyWrapper>
          <S.CopyLabel>
            {intl.formatMessage({ id: "settings.domains.cname" })}
          </S.CopyLabel>
          <CopyableArea singleLine={true} content={cname}>
            {cname}
          </CopyableArea>
        </S.CopyWrapper>

        <ButtonWrapper>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {!isDefault && (
                <>
                  <S.DefaultButton
                    id="make-default-domain-btn"
                    type="submit"
                    aria-label={intl.formatMessage({
                      id: "settings.domains.make_default"
                    })}
                    onClick={handleMakeItDefault}
                  >
                    {capitalize(
                      intl.formatMessage({
                        id: "settings.domains.make_default"
                      })
                    )}
                  </S.DefaultButton>
                  <InfoDialog
                    text={intl.formatMessage({
                      id: "settings.domains.default.info"
                    })}
                  />
                </>
              )}

              {showDeleteButton && (
                <S.DeleteButton
                  variant="tertiary"
                  id="project-domain-delete-btn"
                  type="button"
                  aria-label={intl.formatMessage({
                    id: "settings.domains.delete"
                  })}
                  onClick={onDelete}
                >
                  {capitalize(
                    intl.formatMessage({ id: "settings.domains.delete" })
                  )}
                </S.DeleteButton>
              )}
            </>
          )}
        </ButtonWrapper>
      </S.FormWrapper>
    </S.Wrapper>
  );
};

DomainItem.propTypes = {
  cname: PropTypes.string,
  domain: PropTypes.object,
  errors: PropTypes.object,
  isDefault: PropTypes.bool,
  isLoading: PropTypes.bool,
  makeItDefault: PropTypes.func,
  onDelete: PropTypes.func
};

export default DomainItem;
