import { Map, fromJS, List } from "immutable";

import activityTypes from "Constants/activityTypes";
import {
  normalize,
  getProjectId,
  getOrganizationDescriptionId,
  getEnvironmentId,
  getEnvironmentDescriptionId,
  isJson
} from "Libs/utils";
import logger from "Libs/logger";
import { loadDeployment } from "Reducers/environment/deployment";
import { loadEnvironments } from "Reducers/environment";
import { ACTIVITY_CONTEXT } from "Constants/constants";

const LOAD_ACTIVITY_START = "app/activity/load_start";
const LOAD_ACTIVITY_SUCCESS = "app/activity/load_success";
const LOAD_ACTIVITY_FAILURE = "app/activity/load_failure";
const LOAD_ENVIRONMENT_ACTIVITY_START = "app/activity/environment/load_start";
const LOAD_ENVIRONMENT_ACTIVITY_SUCCESS =
  "app/activity/environment/load_success";
const LOAD_ENVIRONMENT_ACTIVITY_FAILURE =
  "app/activity/environment/load_failure";

const LOAD_PROJECT_ACTIVITY_START = "app/activity/project/load_start";
const LOAD_PROJECT_ACTIVITY_SUCCESS = "app/activity/project/load_success";
const LOAD_PROJECT_ACTIVITY_FAILURE = "app/activity/project/load_failure";

const LOAD_INTEGRATION_ACTIVITY_START = "app/activity/integration/load_start";
const LOAD_INTEGRATION_ACTIVITY_SUCCESS =
  "app/activity/integration/load_success";
const LOAD_INTEGRATION_ACTIVITY_FAILURE =
  "app/activity/integration/load_failure";

const UPDATE_PROJECT_ACTIVITY_START = "app/activity/project/update_start";
const UPDATE_PROJECT_ACTIVITY_SUCCESS = "app/activity/project/update_success";
const UPDATE_PROJECT_ACTIVITY_FAILURE = "app/activity/project/update_failure";

const UPDATE_ENVIRONMENT_ACTIVITY_START =
  "app/activity/environment/update_start";
const UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS =
  "app/activity/environment/update_sucess";
const UPDATE_ENVIRONMENT_ACTIVITY_FAILURE =
  "app/activity/environment/update_failure";

const UPDATE_INTEGRATION_ACTIVITY_START =
  "app/activity/integration/update_start";
const UPDATE_INTEGRATION_ACTIVITY_SUCCESS =
  "app/activity/integration/update_success";
const UPDATE_INTEGRATION_ACTIVITY_FAILURE =
  "app/activity/integration/update_failure";

const LOAD_ACTIVITY_LOG_START = "app/activity/load_log_start";
const LOAD_ACTIVITY_LOG_FRAGMENT = "app/activity/load_log_fragment";
const LOAD_ACTIVITY_LOG_SUCCESS = "app/activity/load_log_success";
const SET_ACTIVITY_FILTER_NAMES = "app/activity/filter/set_names";
const SET_ACTIVITY_FILTER_TAB = "app/activity/filter/tab/set";

export const ActivityTabName = {
  Recent: "recent",
  All: "all"
};

export const setActivityFilterNames = ({ context, filterNames }) => ({
  type: SET_ACTIVITY_FILTER_NAMES,
  payload: { context, filterNames }
});

export const setActivitiesTab = tab => ({
  type: SET_ACTIVITY_FILTER_TAB,
  payload: tab
});

export const getActivityTypes = types => {
  let filterTypes = [];
  if (types && types.length > 0 && types !== "all_type") {
    filterTypes = types.reduce((typeList, type) => {
      if (!activityTypes[type]) {
        return typeList;
      }
      return typeList.concat(activityTypes[type].types);
    }, []);
  }
  return filterTypes;
};

export const getActivityTab = state =>
  state.activity.getIn(["filters", "tab"], ActivityTabName.Recent);

const checkDeletedEnvironments = (
  dispatch,
  activities,
  organizationId,
  projectId
) => {
  const deletedEnvs = activities.reduce((acc, cu) => {
    if (cu.type === "environment.delete") {
      acc.push(cu.parameters.environment);
    }
    return acc;
  }, []);

  if (deletedEnvs.length) {
    dispatch(
      loadEnvironments({
        organizationId,
        projectId
      })
    );
  }
};

export const loadActivitySuccess = activity => {
  return (dispatch, getState) => {
    dispatch({ type: LOAD_ACTIVITY_START });

    const projectDescriptionId = activity.project;
    const organizationDescriptionId = getOrganizationDescriptionId(
      getState,
      projectDescriptionId
    );

    const reloadDeployment = environmentId => {
      if (
        activity.type === "environment.push" &&
        activity.state === "complete"
      ) {
        dispatch(
          loadDeployment(
            organizationDescriptionId,
            activity.project,
            environmentId,
            { hasRedeployed: true }
          )
        );
      }
    };

    try {
      activity.environments.forEach(environmentId => {
        const environmentDescriptionId = getEnvironmentDescriptionId(
          getState,
          organizationDescriptionId,
          projectDescriptionId,
          environmentId
        );

        if (!environmentDescriptionId) {
          return false;
        }

        dispatch({
          type: LOAD_ACTIVITY_SUCCESS,
          payload: activity,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            environmentDescriptionId
          }
        });
        reloadDeployment(environmentId);
      });
    } catch (error) {
      if (![404, 403, 400].includes(error.code)) {
        const errorMessage = isJson(error)
          ? error
          : "An error occurred while attempting to load activity.";
        logger(errorMessage, {
          action: LOAD_ACTIVITY_FAILURE,
          meta: {
            organizationDescriptionId,
            projectDescriptionId
          }
        });
      }
      dispatch({ type: LOAD_ACTIVITY_FAILURE, error: true, payload: error });
    }
  };
};

export const loadProjectActivities = (
  projectDescriptionId,
  organizationDescriptionId,
  filterNames,
  context
) => {
  return async dispatch => {
    dispatch({ type: LOAD_PROJECT_ACTIVITY_START });
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const activities = await client.getProjectActivities(
        projectDescriptionId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      checkDeletedEnvironments(
        dispatch,
        activities,
        organizationDescriptionId,
        projectDescriptionId
      );

      dispatch({
        type: LOAD_PROJECT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId
        }
      });
    } catch (error) {
      if (![404, 403, 400].includes(error.code)) {
        logger(error, {
          action: LOAD_PROJECT_ACTIVITY_FAILURE,
          meta: {
            organizationDescriptionId,
            projectDescriptionId
          }
        });
      }

      dispatch({
        type: LOAD_PROJECT_ACTIVITY_FAILURE,
        error: true,
        payload: error
      });
    }
  };
};

export const loadEnvironmentActivities = (
  projectDescriptionId,
  environmentDescriptionId,
  organizationDescriptionId,

  filterNames,
  context
) => {
  return async dispatch => {
    dispatch({ type: LOAD_ENVIRONMENT_ACTIVITY_START });
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentDescriptionId);

      const activities = await client.getEnvironmentActivities(
        projectDescriptionId,
        encodedEnvId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      checkDeletedEnvironments(
        dispatch,
        activities,
        organizationDescriptionId,
        projectDescriptionId
      );

      const activitiesForEnvironment = activities.map(activity => activity.id);

      dispatch({
        type: LOAD_ENVIRONMENT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          environmentActivity: activitiesForEnvironment
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        const errorMessage = isJson(err)
          ? err
          : "An error occurred while attempting to load environment.";
        logger(errorMessage, {
          action: "environment_load_activities",
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId
          }
        });
      }
      dispatch({
        type: LOAD_ENVIRONMENT_ACTIVITY_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadIntegrationActivities = (
  organizationDescriptionId,
  projectDescriptionId,
  integrationDescriptionId,

  filterNames,
  context
) => {
  return async dispatch => {
    dispatch({ type: LOAD_INTEGRATION_ACTIVITY_START });
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedIntegrationId = encodeURIComponent(integrationDescriptionId);

      const activities = await client.getIntegrationActivities(
        projectDescriptionId,
        encodedIntegrationId,
        filters
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      const activitiesForIntegration = activities.map(activity => activity.id);

      dispatch({
        type: LOAD_INTEGRATION_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          integrationDescriptionId,
          integrationActivity: activitiesForIntegration
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: "integration_load_activities",
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId
          }
        });
      }
      dispatch({
        type: LOAD_INTEGRATION_ACTIVITY_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadMoreProjectActivities = (
  projectDescriptionId,
  organizationDescriptionId,

  filterNames,
  context
) => {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_PROJECT_ACTIVITY_START });
    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    const activitiesAlreadyLoaded = getState().activity || new Map();
    let activitiesAlreadyLoadedForProject = activitiesAlreadyLoaded.getIn(
      ["byProject", "data", organizationDescriptionId, projectDescriptionId],
      new Map()
    );

    let startsAt = false;

    if (filters.length !== 0) {
      activitiesAlreadyLoadedForProject =
        activitiesAlreadyLoadedForProject.filter(activity =>
          filters ? filters.indexOf(activity.type) !== -1 : true
        );
    }

    // If we already have activities for this environment
    // We extract the older
    activitiesAlreadyLoadedForProject.valueSeq().forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() < new Date(startsAt).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const project = getState().project.getIn([
        "data",
        organizationDescriptionId,
        projectDescriptionId
      ]);
      const activities = await project.getActivities(filters, startsAt);
      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      // Ensure project level activity is loaded not just environment level activity.
      dispatch({
        type: UPDATE_PROJECT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          filters
        }
      });
    } catch (error) {
      if (![404, 403].includes(error.code)) {
        logger(error, {
          action: UPDATE_PROJECT_ACTIVITY_FAILURE,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            filters
          }
        });
      }
      dispatch({
        type: UPDATE_PROJECT_ACTIVITY_FAILURE,
        error: true,
        payload: error
      });
    }
  };
};

export const loadMoreEnvironmentActivities = (
  projectDescriptionId,
  environmentDescriptionId,
  organizationDescriptionId,

  filterNames,
  context
) => {
  return async (dispatch, getState) => {
    const activitiesAlreadyLoaded =
      getState().activity.get("byEnvironment") || new Map();
    let activitiesAlreadyLoadedForProject = activitiesAlreadyLoaded.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      ],
      []
    );

    const filters = getActivityTypes(filterNames);
    dispatch({ type: UPDATE_ENVIRONMENT_ACTIVITY_START });
    dispatch(setActivityFilterNames({ context, filterNames }));
    let startsAt = false;

    if (filters.length !== 0) {
      activitiesAlreadyLoadedForProject =
        activitiesAlreadyLoadedForProject.filter(activity =>
          filters ? filters.indexOf(activity.type) !== -1 : true
        );
    }

    // If we already have activities for this environment
    // We extract the older
    activitiesAlreadyLoadedForProject.forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() < new Date(startsAt).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const environmentId = getEnvironmentId(
        getState,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      );
      const activities = await client.getEnvironmentActivities(
        getProjectId(getState, projectDescriptionId),
        encodeURIComponent(environmentId),
        filters && filters !== "all_type" ? filters : undefined,
        startsAt
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      dispatch({
        type: UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          filters
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS,
          meta: {
            organizationDescriptionId,
            environmentDescriptionId,
            projectDescriptionId,
            filters
          }
        });
      }
      dispatch({
        type: UPDATE_ENVIRONMENT_ACTIVITY_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

export const loadMoreIntegrationActivities = (
  organizationDescriptionId,
  projectDescriptionId,
  integrationDescriptionId,

  filterNames,
  context
) => {
  return async (dispatch, getState) => {
    const activitiesAlreadyLoaded =
      getState().activity.get("byIntegration") || new Map();
    let activitiesAlreadyLoadedForIntegration = activitiesAlreadyLoaded.getIn(
      [
        "data",
        organizationDescriptionId,
        projectDescriptionId,
        integrationDescriptionId
      ],
      []
    );

    const filters = getActivityTypes(filterNames);
    dispatch(setActivityFilterNames({ context, filterNames }));
    dispatch({ type: UPDATE_INTEGRATION_ACTIVITY_START });

    let startsAt = false;

    if (filters.length !== 0) {
      activitiesAlreadyLoadedForIntegration =
        activitiesAlreadyLoadedForIntegration.filter(activity =>
          filters ? filters.indexOf(activity.type) !== -1 : true
        );
    }

    // If we already have activities for this integration
    // We extract the older
    activitiesAlreadyLoadedForIntegration.forEach(activity => {
      if (
        !startsAt ||
        new Date(activity.created_at).getTime() < new Date(startsAt).getTime()
      )
        startsAt = activity.created_at;
    });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;

      const encodedIntegrationId = encodeURIComponent(integrationDescriptionId);

      const activities = await client.getIntegrationActivities(
        projectDescriptionId,
        encodedIntegrationId,
        filters && filters !== "all_type" ? filters : undefined,
        startsAt
      );

      let hasMore = false;
      if (activities.length > 9) {
        hasMore = true;
        activities.pop();
      }

      dispatch({
        type: UPDATE_INTEGRATION_ACTIVITY_SUCCESS,
        payload: {
          activities
        },
        meta: {
          hasMore,
          organizationDescriptionId,
          projectDescriptionId,
          integrationDescriptionId,
          filters
        }
      });
    } catch (err) {
      if (![404, 403].includes(err.code)) {
        logger(err, {
          action: UPDATE_INTEGRATION_ACTIVITY_SUCCESS,
          meta: {
            organizationDescriptionId,
            projectDescriptionId,
            integrationDescriptionId,
            filters
          }
        });
      }
      dispatch({
        type: UPDATE_INTEGRATION_ACTIVITY_FAILURE,
        error: true,
        payload: err
      });
    }
  };
};

// SELECTORS

// Project activities
const selectAllProjectActivities = (state, { organizationId, projectId }) => {
  return state.activity.getIn(
    ["byProject", "data", organizationId, projectId],
    new Map()
  );
};

const selectProjectActivitiesByStatus = status => (state, params) => {
  const allActivities = selectAllProjectActivities(state, params);
  return allActivities.filter(a => a.state === status);
};

const selectInProgressProjectActivities =
  selectProjectActivitiesByStatus("in_progress");
const selectPendingProjectActivities =
  selectProjectActivitiesByStatus("pending");
const selectCompletedProjectActivities =
  selectProjectActivitiesByStatus("complete");

const selectProjectHasMoreActivities = state =>
  state.activity.getIn(["byProject", "hasMore"], false);

const selectProjectActivityLoadingState = state =>
  state.activity?.getIn(["byProject", "loading"], true);

const selectProjectActivityLoadingMoreState = state =>
  state.activity?.getIn(["byProject", "loadingMore"], false);

// Environment activities
const selectAllEnvironmentActivities = (
  state,
  { organizationId, projectId, environmentId }
) => {
  return state.activity.getIn(
    ["byEnvironment", "data", organizationId, projectId, environmentId],
    new Map()
  );
};

const selectEnvironmentActivitiesByStatus = status => (state, params) => {
  const activities = selectAllEnvironmentActivities(state, params);
  return activities.filter(a => a.state === status);
};

const selectInProgressEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("in_progress");
const selectPendingEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("pending");
const selectCompletedEnvironmentActivities =
  selectEnvironmentActivitiesByStatus("complete");

const selectEnvironmentActivityLoadingState = state =>
  state.activity?.getIn(["byEnvironment", "loading"], true);

const selectEnvironmentActivityLoadingMoreState = state =>
  state.activity?.getIn(["byEnvironment", "loadingMore"], false);

const selectEnvironmentHasMoreActivities = state =>
  state.activity.getIn(["byEnvironment", "hasMore"], false);

// Integration activities
const selectAllIntegrationActivities = (
  state,
  { organizationId, projectId, integrationId }
) => {
  return state.activity.getIn(
    ["byIntegration", "data", organizationId, projectId, integrationId],
    new Map()
  );
};

const selectIntegrationActivitiesByStatus = status => (state, params) => {
  const activities = selectAllIntegrationActivities(state, params);
  return activities.filter(a => a.state === status);
};

const selectInProgressIntegrationActivities =
  selectIntegrationActivitiesByStatus("in_progress");
const selectPendingIntegrationActivities =
  selectIntegrationActivitiesByStatus("pending");
const selectCompletedIntegrationActivities =
  selectIntegrationActivitiesByStatus("complete");

const selectIntegrationActivityLoadingState = state =>
  state.activity?.getIn(["byIntegration", "loading"], true);

const selectIntegrationActivityLoadingMoreState = state =>
  state.activity?.getIn(["byIntegration", "loadingMore"], false);

const selectIntegrationHasMoreActivities = state =>
  state.activity.getIn(["byIntegration", "hasMore"], false);

export const getSelectors = (context, params) => {
  const selectors = {
    environment: {
      all: state => selectAllEnvironmentActivities(state, params),
      inProgress: state => selectInProgressEnvironmentActivities(state, params),
      pending: state => selectPendingEnvironmentActivities(state, params),
      completed: state => selectCompletedEnvironmentActivities(state, params),
      isLoading: state => selectEnvironmentActivityLoadingState(state, params),
      isLoadingMore: state =>
        selectEnvironmentActivityLoadingMoreState(state, params),
      hasMore: state => selectEnvironmentHasMoreActivities(state, params)
    },
    integration: {
      all: state => selectAllIntegrationActivities(state, params),
      inProgress: state => selectInProgressIntegrationActivities(state, params),
      pending: state => selectPendingIntegrationActivities(state, params),
      completed: state => selectCompletedIntegrationActivities(state, params),
      isLoading: state => selectIntegrationActivityLoadingState(state, params),
      isLoadingMore: state =>
        selectIntegrationActivityLoadingMoreState(state, params),
      hasMore: state => selectIntegrationHasMoreActivities(state, params)
    },
    project: {
      all: state => selectAllProjectActivities(state, params),
      inProgress: state => selectInProgressProjectActivities(state, params),
      pending: state => selectPendingProjectActivities(state, params),
      completed: state => selectCompletedProjectActivities(state, params),
      isLoading: state => selectProjectActivityLoadingState(state, params),
      isLoadingMore: state =>
        selectProjectActivityLoadingMoreState(state, params),
      hasMore: state => selectProjectHasMoreActivities(state, params)
    }
  };

  // context integration like 'integration.gitlab'
  const kind = context.startsWith("integration") ? "integration" : context;
  return selectors[kind] || selectors["project"];
};

/**
 * Update activity store following an activity subscription dispatch, taking
 * into consideration the current filter in store applied to such activity
 * @returns An updated state with the new received activity if activity.type
 * is found in the list of filters or filters is empty
 */
const updateSubscriptionActivity = ({
  state,
  activityPath,
  activity,
  context
}) => {
  const filterNames = state.getIn(["filters", context], []);
  const filters = getActivityTypes(filterNames);
  if (filters.length && !filters.includes(activity.type)) return state;
  return state.setIn(activityPath, fromJS(activity));
};

export const getActivityFilters = (state, context) => {
  return state.activity.getIn(["filters", context]);
};

export default function activityReducer(state = new Map(), action) {
  switch (action.type) {
    case UPDATE_ENVIRONMENT_ACTIVITY_START:
      return state.setIn(["byEnvironment", "loadingMore"], true);
    case UPDATE_PROJECT_ACTIVITY_START:
      return state.setIn(["byProject", "loadingMore"], true);
    case UPDATE_INTEGRATION_ACTIVITY_START:
      return state.setIn(["byIntegration", "loadingMore"], true);
    case LOAD_ACTIVITY_SUCCESS: {
      const byProject = updateSubscriptionActivity({
        state,
        activityPath: [
          "byProject",
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.payload.id
        ],
        activity: action.payload,
        context: ACTIVITY_CONTEXT.Project
      });
      const byEnvironment = updateSubscriptionActivity({
        state: byProject,
        activityPath: [
          "byEnvironment",
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.payload.id
        ],
        activity: action.payload,
        context: ACTIVITY_CONTEXT.Environment
      });
      return updateSubscriptionActivity({
        state: byEnvironment,
        activityPath: [
          "byIntegration",
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.integrationDescriptionId,
          action.payload.id
        ],
        activity: action.payload,
        context: ACTIVITY_CONTEXT.Integration
      })
        .setIn(["byProject", "loading"], false)
        .setIn(["byEnvironment", "loading"], false)
        .setIn(["byIntegration", "loading"], false);
    }
    case LOAD_PROJECT_ACTIVITY_START:
      return state.setIn(["byProject", "loading"], true);
    case LOAD_PROJECT_ACTIVITY_SUCCESS:
      return state
        .setIn(["byProject", "loading"], false)
        .setIn(["byProject", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byProject",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId
          ],
          fromJS(normalize(action.payload.activities))
        );
    case UPDATE_PROJECT_ACTIVITY_SUCCESS:
      return state
        .setIn(["byProject", "loading"], false)
        .setIn(["byProject", "loadingMore"], false)
        .setIn(["byProject", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byProject",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId
          ],
          state
            .getIn(
              [
                "byProject",
                "data",
                action.meta.organizationDescriptionId,
                action.meta.projectDescriptionId
              ],
              new Map()
            )
            .concat(fromJS(normalize(action.payload.activities)))
        );
    case LOAD_ENVIRONMENT_ACTIVITY_START:
      return state.setIn(["byEnvironment", "loading"], true);
    case LOAD_ENVIRONMENT_ACTIVITY_SUCCESS:
      return state
        .setIn(["byEnvironment", "loading"], false)
        .setIn(["byEnvironment", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byEnvironment",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          fromJS(normalize(action.payload.activities))
        );
    case UPDATE_ENVIRONMENT_ACTIVITY_SUCCESS:
      return state
        .setIn(["byEnvironment", "loading"], false)
        .setIn(["byEnvironment", "loadingMore"], false)
        .setIn(["byEnvironment", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byEnvironment",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.environmentDescriptionId
          ],
          state
            .getIn(
              [
                "byEnvironment",
                "data",
                action.meta.organizationDescriptionId,
                action.meta.projectDescriptionId,
                action.meta.environmentDescriptionId
              ],
              new Map()
            )
            .concat(fromJS(normalize(action.payload.activities)))
        );
    case LOAD_INTEGRATION_ACTIVITY_START:
      return state.setIn(["byIntegration", "loading"], true);
    case LOAD_INTEGRATION_ACTIVITY_SUCCESS:
      return state
        .setIn(["byIntegration", "loading"], false)
        .setIn(["byIntegration", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byIntegration",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.integrationDescriptionId
          ],
          fromJS(normalize(action.payload.activities))
        );
    case UPDATE_INTEGRATION_ACTIVITY_SUCCESS:
      return state
        .setIn(["byIntegration", "loading"], false)
        .setIn(["byIntegration", "loadingMore"], false)
        .setIn(["byIntegration", "hasMore"], action.meta.hasMore)
        .setIn(
          [
            "byIntegration",
            "data",
            action.meta.organizationDescriptionId,
            action.meta.projectDescriptionId,
            action.meta.integrationDescriptionId
          ],
          state
            .getIn(
              [
                "byIntegration",
                "data",
                action.meta.organizationDescriptionId,
                action.meta.projectDescriptionId,
                action.meta.integrationDescriptionId
              ],
              new Map()
            )
            .concat(fromJS(normalize(action.payload.activities)))
        );
    case LOAD_PROJECT_ACTIVITY_FAILURE:
    case LOAD_ENVIRONMENT_ACTIVITY_FAILURE:
    case LOAD_INTEGRATION_ACTIVITY_FAILURE:
    case UPDATE_PROJECT_ACTIVITY_FAILURE:
    case UPDATE_ENVIRONMENT_ACTIVITY_FAILURE:
    case UPDATE_INTEGRATION_ACTIVITY_FAILURE:
      return state
        .setIn(["byProject", "loading"], false)
        .setIn(["byProject", "loadingMore"], false)
        .setIn(["byEnvironment", "loading"], false)
        .setIn(["byEnvironment", "loadingMore"], false)
        .setIn(["byIntegration", "loading"], false)
        .setIn(["byIntegration", "loadingMore"], false)
        .set("error", action.payload);
    case LOAD_ACTIVITY_LOG_START:
      return state.setIn(["log", action.payload, "streaming"], true);
    case LOAD_ACTIVITY_LOG_FRAGMENT:
      return state.setIn(
        ["log", action.meta.id, "data"],
        state
          .getIn(["log", action.meta.id, "data"], new List())
          .push(action.payload)
      );
    case LOAD_ACTIVITY_LOG_SUCCESS:
      return state.setIn(["log", action.payload, "streaming"], false);
    case SET_ACTIVITY_FILTER_NAMES:
      return state.setIn(
        ["filters", action.payload.context],
        action.payload.filterNames
      );
    case SET_ACTIVITY_FILTER_TAB:
      return state.setIn(["filters", "tab"], action.payload);
    default:
      return state;
  }
}
